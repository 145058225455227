<style lang="scss" scoped>
.landing {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 0;

  .landing-scroll-down-arrow {
    position: absolute;
    bottom: 0;
  }

  .landing-logo {
    height: auto;
    width: 80%;
    max-width: 420px;
  }

  .landing-caption {
    font-family: "adobe-handwriting-ernie", serif;
    color: $primary;
    font-size: 2.25rem;
    padding: 24px 0;
    @media screen and (min-device-width: 900px) {
      font-size: 3rem;
    }
  }

  .social-links {
    //position: absolute;
    //bottom: 64px;
    .social-link {
      margin: 0 12px;
      color: $secondary-dark;
      background-color: $primary;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      //text-indent: -99999px;
    }
  }
}
</style>

<template>
  <header class="landing">
    <img
      class="landing-logo"
      src="../assets/logo.svg"
      alt="Logo Patrol"
      height="149"
      width="313"
    />
    <h2 class="landing-caption">The Weekly Brunch</h2>
    <div class="social-links">
      <a
        href="https://www.instagram.com/patrol_paris"
        target="_blank"
        rel="noopener"
        class="social-link hide-text"
      >
        <icon-instagram />
      </a>
      <a
        href="https://www.facebook.com/patrolparis"
        target="_blank"
        rel="noopener"
        class="social-link hide-text"
      >
        <icon-facebook />
      </a>
    </div>
    <scroll-down-arrow class="landing-scroll-down-arrow" />
  </header>
</template>

<script>
export default {
  name: "Landing"
};
</script>
